import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setTherapeuticAreaTabEvent, TherapeuticAreaTabClickEvent } from '../utils/analyticsDataLayer';
import { getActiveGroups } from '../utils/oneTrustConsent';

const HashToTherapeuticAreaTabClickEvent = {
  overview: 'Overview',
  products: 'Products',
};

const useSetTherapeuticAreaTabToAnalytics = (area: string): void => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && area) {
      if (getActiveGroups().includes('C0004')) {
        setTherapeuticAreaTabEvent(
          TherapeuticAreaTabClickEvent[
            HashToTherapeuticAreaTabClickEvent[
              hash.slice(1) as keyof typeof HashToTherapeuticAreaTabClickEvent
            ] as keyof typeof TherapeuticAreaTabClickEvent
          ],
          area
        );
      }
    }
  }, [area, hash]);
};

export default useSetTherapeuticAreaTabToAnalytics;
