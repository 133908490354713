import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { PageType, setPageViewEvent } from '../utils/analyticsDataLayer';
import { getActiveGroups } from '../utils/oneTrustConsent';

const useSetPageEventOnIdChange = (pageType: PageType): void => {
  const { id } = useParams();
  const ref = useRef('');

  useEffect(() => {
    if (id && ref.current !== id) {
      ref.current = id;
      if (getActiveGroups().includes('C0004')) {
        setPageViewEvent(pageType);
      }
    }
  }, [id, pageType]); // Include activeGroups in the dependency array
};

export default useSetPageEventOnIdChange;
